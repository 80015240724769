<template>
    <div class="home">
        <div class="homeTop">
            <div class="logo" @click="homeBtn">
                <img class="imgHeight" src="../assets/images/logo.png" />
                <img class="logoText" src="../assets/images/logo-Text.jpg" />
            </div>
            <div class="text" @click="aboutBtn">
                关于我们
            </div>
        </div>
		<div class="homeContentBox" :style="{'height':height - 125 + 'px'}">
			<div class="homeContent" :style="{'height':height - 125 + 'px'}">
			    <img class="logoBg" src="../assets/images/logo-Images.png" />
			    <img class="logoArt" src="../assets/images/logo-Art.jpg" />
			    <div class="qr-code-box">
			        <div class="qr-code">
			            <div class="image">
			                <img class="img-code" src="../assets/images/QR-Code-WeChat.jpeg" />
			            </div>
			            <div style="flex:1;padding-right:40px;">
			                <div class="img-icon-box">
			                    <img class="img-icon" src="../assets/images/WeChat.png" />微信搜一搜
			                </div>
			                <div class="img-icon-box">
			                    <img class="img-icon" style="height:25px;" src="../assets/images/search.png" />熊猫艺术公社
			                </div>
			            </div>
			        </div>
			        <div class="qr-code" style="margin-top:40px;">
			            <div class="image">
			                <img class="img-code" src="../assets/images/QR-Code-Web.png" />
			            </div>
			            <div style="flex:1;padding-right:40px;">
			                <div class="img-icon-box">
			                    <img class="img-icon" src="../assets/images/scan.png" />手机扫一扫
			                </div>
			                <div class="img-icon-box">
			                    <img class="img-icon" style="height:25px;" src="../assets/images/search.png" />访问市场
			                </div>
			            </div>
			        </div>
			    </div>
			</div>
		</div>
		<div class="homeBottom">
		    <div class="logo">
		        <img class="imgHeight" src="../assets/images/logo.png" />
		        <img class="logoText" src="../assets/images/logo-Text.jpg" />
		    </div>
		    <div class="text">
		        Copyright © 2022 艺集星链（杭州）科技有限公司 <span @click="recordBtn">浙ICP备2022024596号-2</span>  浙江省杭州市拱墅区浙窑文创园
		    </div>
		</div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                height:0,
            }
        },
        created() {
            
        },
        methods:{
            homeBtn(){
                this.$router.push({
                    path:'/'
                })
            },
            aboutBtn(){
                this.$router.push({
                    path:'/about'
                })
            },
			recordBtn(){
				window.open('https://beian.miit.gov.cn/');
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				let w = document.documentElement.clientWidth || document.body.clientWidth;
				this.height = h;
			},
        },
		beforeMount(){
			this.setHeight();
		},
		mounted(){
			let that = this;
			window.addEventListener('resize',() => that.setHeight(), false);
		},
		beforeDestroy(){
			window.removeEventListener('resize', this.setHeight(), false)
		}
    }
</script>
<style scoped>
    .home{
        margin:0px auto;
    }
    .homeTop{
		width:1440px;
        height:60px;
		margin:0px auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px 50px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .homeTop .logo,.homeBottom .logo{
        height:40px;
    }
    .homeTop .imgHeight,.homeBottom .imgHeight{
        height:40px;
    }
    .homeTop .logoText,.homeBottom .logoText{
        height:30px;
    }
    .homeTop .text{
        font-size:18px;
        font-weight:bold;
    }
	.homeContentBox{
		background:linear-gradient(to bottom,rgba(64,149,229,1) 0%,rgba(106,172,235,0.78) 43%,rgba(255,255,255,0) 100%);
	}
    .homeContent{
		width:1440px;
        height:748px;
		margin:0px auto;
        position: relative;
    }
    .homeContent .logoBg{
        width:600px;
        height:650px;
        object-fit: contain;
    }
    .homeContent .logoArt{
        width:310px;
        height:115px;
        object-fit: contain;
        position: absolute;
        bottom: 0px;
        right:0px;
    }
    .qr-code-box{
        width:450px;
        position: absolute;
        top:200px;
        right:100px;
    }
    .qr-code{
        border:1px solid #fff;
        border-radius: 10px;
        padding:10px;
        display: flex;
    }
    .qr-code .image{
        flex:0 0 100px;
        margin-right:40px;
    }
    .qr-code .img-code{
        width:100px;
        height:100px;
        border-radius: 10px;
    }
    .qr-code .img-icon-box{
        height:30px;
        line-height: 30px;
        color:#fff;
        font-size:20px;
        font-weight:bold;
        margin-top:10px;
    }
    .qr-code .img-icon-box:last-child{
        height:25px;
        line-height: 25px;
        background:#fff;
        color:#101010;
        font-size: 16px;
        border-radius:5px;
        padding:5px;
        margin-top:15px;
    }
    .qr-code .img-icon{
        height:30px;
        margin-right:20px;
        vertical-align: bottom;
    }
    .homeBottom{
        width:1100px;
        height:65px;
        margin:0px auto;
    }
    .homeBottom .text{
        font-size:15px;
        padding-left:10px;
        margin-top:5px;
    }
	.homeBottom .text span{
		cursor: pointer;
	}
</style>
